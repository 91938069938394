<template>
  <div>
    <el-card class="box-card" v-loading="loading">
      <el-row :gutter="20" v-if="showButtons">
        <el-col :span="24">
          <el-button type="primary" icon="el-icon-edit" size="medium" plain @click="openScribe"
            >Open Scribe</el-button
          >
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :md="24" :lg="12">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Patient Info</span>
            </div>
            <el-alert
              title="Patient Info"
              type="info"
              show-icon
              :closable="false"
              description="Feel free to complete the form below to provide optional patient information for the session. Ensure that the Patient ID is unique for each patient. Once the information is filled in, click the 'Open Scribe' button to open the widget with this patient information."
            >
            </el-alert>
            <el-form :label-position="'top'" label-width="100px" :model="patientInfo">
              <el-form-item label="Patient ID">
                <el-input v-model="patientInfo.id"></el-input>
              </el-form-item>
              <el-form-item label="Patient Name">
                <el-input v-model="patientInfo.name"></el-input>
              </el-form-item>
              <el-form-item label="Patient Gender">
                <el-select v-model="patientInfo.gender" placeholder="Select">
                  <el-option
                    v-for="item in genderOpt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Patient DOB">
                <el-date-picker v-model="patientInfo.dob" type="date" placeholder="Pick a day">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
        <el-col :md="24" :lg="12">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Custom Template</span>
            </div>

            <el-alert
              title="Custom Template"
              type="info"
              show-icon
              :closable="false"
              description="If you wish to generate a structured response, you can provide a JSON template for Scribe to fill. The structured response will be generated after the session ends. You may use the template below or edit it as needed."
            >
            </el-alert>
            <div style="margin-top: 20px; margin-bottom: 20px">
              <el-checkbox v-model="useTemplate" label="Use Custom Template" border></el-checkbox>
            </div>
            <JSONEditor ref="jsonEditor" v-model="responses" />
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import JSONEditor from "@/components/JSONEditor";
import { duration } from "moment";

export default {
  components: {
    JSONEditor,
  },
  data() {
    return {
      loading: true,
      apiKey: "",
      email: "",
      enviroment: "staging",
      internalId: "123",
      showButtons: false,
      useTemplate: false,
      heidiInstance: null,
      genderOpt: [
        {
          value: "Male",
          label: "Male",
        },
        {
          value: "Female",
          label: "Female",
        },
      ],
      patientInfo: {
        id: "",
        name: "",
        gender: "",
        dob: "",
      },
      templateData: {
        content: "...",
        responses: [],
      },
      responses: [
        {
          questionId: "31",
          question: "Medical History",
          answerType: "SingleResponse",
          answerOptions: ["Checked - No Changes", "Updated (Please provide details)"],
        },
        {
          questionId: "32",
          question: "Who did the Patient Attended With",
          answerType: "SingleResponse",
          answerOptions: ["Parent", "Guardian", "Self"],
        },
        {
          questionId: "33",
          question: "Mucosa, Tongue, Fom, Palate ",
          answerType: "TextArea",
          answerOptions: [],
        },
        {
          questionId: "34",
          question: "Caries Risk",
          answerType: "SingleResponse",
          answerOptions: ["Low Risk", "Medium Risk", "High Risk"],
        },
        {
          questionId: "35",
          question: "Development of Dentition",
          answerType: "SingleResponse",
          answerOptions: ["Early", "Normal", "Delayed"],
        },
        {
          questionId: "36",
          question: "Charting Completed ?",
          answerType: "SingleResponse",
          answerOptions: ["Yes", "No"],
        },
        {
          questionId: "37",
          question: "Patient Cooperation",
          answerType: "SingleResponse",
          answerOptions: ["Good", "Poor"],
        },
        {
          questionId: "38",
          question: "Patient Diagnosis",
          answerType: "TextArea",
          answerOptions: [],
        },
        {
          questionId: "39",
          question: "Discussions | Child Exam",
          answerType: "MultipleResponse",
          answerOptions: [
            "OHI - CheckBox All Checked (brush then supervise brushing, twice daily, spit do not rinse out, fluoride tp, children electric TB)",
            "Diet advice  Check Box All Checked (hidden sugars, limit to meal times and no more than 4 times per day)",
          ],
        },
        {
          questionId: "40",
          question: "Treatment Plan Agreement",
          answerType: "TextArea",
          answerOptions: [],
        },
      ],
      heidiOptions: {
        token: "VALID_JWT_TOKEN",
        target: "#heidi",
        onInit: this.onHeidiInit,
        onReady: this.onHeidiReady,
      },
    };
  },
  methods: {
    async getToken() {
      const responses = await fetch(
        `https://registrar.api.heidihealth.com/api/v2/ml-scribe/open-api/jwt?email=${this.email}&third_party_internal_id=${this.internalId}`,
        {
          method: "GET",
          headers: {
            "Heidi-Api-Key": this.apiKey,
          },
        }
      );

      const data = await responses.json();
      this.heidiOptions.token = data.token;
      this.loading = false;
      this.loadHeidi();
    },
    loadHeidi() {
      // Load the Heidi script when the component is mounted
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://registrar.widget.heidihealth.com/staging/v1.2.1/heidi.js";

      const scripts = document.getElementsByTagName("script");

      let loaded = false;
      for (let index = 0; index < scripts.length; index++) {
        const element = scripts[index];
        if (element.src.includes("heidi.js")) {
          loaded = true;
        }
      }

      if (loaded) {
        this.showButtons = true;
      } else {
        document.head.appendChild(script);
        script.addEventListener("load", () => {
          new Heidi(this.heidiOptions);
        });
      }
    },
    openScribe() {
      if (this.useTemplate) {
        this.openHeidiWithTemplate();
      } else {
        this.openHeidi();
      }
    },
    openHeidiWithTemplate() {
      this.templateData.responses = this.responses;

      if (this.patientInfo.id) {
        Heidi.open({
          template: this.templateData,
          patient: this.patientInfo,
        });
      } else {
        Heidi.open({
          template: this.templateData,
        });
      }
    },
    openHeidi() {
      if (this.patientInfo.id) {
        Heidi.open({ patient: this.patientInfo });
      } else {
        Heidi.open();
      }
    },
    closeHeidi() {
      Heidi.close();
    },
    onHeidiInit() {
      this.showButtons = true; // Show the buttons when Heidi is initialized
    },
    onHeidiReady() {
      Heidi.onPushData((data) => {
        this.$notify.info({
          title: "Info",
          position: "top-left",
          message: "Your note has been successfully saved!",
        });
      });

      Heidi.onSessionStarted((sessionId) => {
        console.log("Session ID:", sessionId); // Handle session started

        // Modify widget
        const iframe = document.querySelector(".heidi-iframe");
        const iframeDocument = iframe.contentDocument;
        const leftHeader = iframeDocument.getElementById("widget-header-left-section");
        const rightHeader = iframeDocument.getElementById("widget-header-right-section");

        const logoSVG = leftHeader.querySelector("svg");
        if (logoSVG) {
          logoSVG.style.display = "none"; // Hide the logo
        }

        const buttons = rightHeader.querySelectorAll("button");

        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === "attributes" && mutation.attributeName === "data-state") {
              if (buttons[1]) {
                const buttonClosed = buttons[1].getAttribute("data-state") === "closed";
                if (!buttonClosed) {
                  const tooltipElement = buttons[1].nextElementSibling;

                  const tooltipDiv = tooltipElement.querySelector("div");
                  if (tooltipDiv) {
                    const textNode = tooltipDiv.childNodes[0];
                    textNode.nodeValue = "Close";
                  }

                  const tooltipSpan = tooltipElement.querySelectorAll("span");
                  if (tooltipSpan[1]) {
                    tooltipSpan[1].textContent = "Close";
                  }
                }
              }

              const dropdownMenu = iframeDocument.querySelector('[role="menu"]');
              if (dropdownMenu) {
                const menuItems = dropdownMenu.querySelectorAll('[role="menuitem"]');
                if (menuItems.length === 3) {
                  const lastMenuItem = menuItems[2];
                  lastMenuItem.style.display = "none"; // Hide last menu
                }
              }
            }
          });
        });

        // Configure the observer
        observer.observe(iframeDocument, {
          childList: true, // Observe direct children
          subtree: true, // Observe all descendants
          attributes: true, // Observe changes to attributes
        });
      });
    },
  },
  mounted() {
    this.apiKey = this.$store.state.modules.scribe.apiKey;
    this.email = this.$store.state.modules.scribe.email;
    this.enviroment = this.$store.state.modules.scribe.environment;
    if (this.apiKey && this.email) {
      this.getToken();
    } else {
      this.loading = false;
    }
  },
};
</script>
